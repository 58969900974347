import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'gatsby';

import Layout from '../layouts/mainLayout';

import ContactForm from '../components/contactForm';

import { Wrapper } from '../styles/pages/aboutus';

import config from '../config';

export default () => (
  <Layout>
    <Helmet>
      <meta
        name="description"
        content="Sci-Tech Carpet Cleaning is the premium cleaning company for your carpets, area rugs, air ducts, upholstery, tile, and more.  We have serviced the Roanoke and New River Valley areas for over 17 years."
      />
      <title>
        Premium Carpet and Rug Cleaning Services in Roanoke and the NRV
      </title>
    </Helmet>
    <Wrapper>
      <h1 className="underlined">About Us</h1>
      <p>
        For more than a decade, Sci-Tech Carpet Cleaning has been making homes a
        little happier, one carpet at a time. Sci-Tech Carpet Cleaning was
        founded in 1999 on the simple principle of staying on the cutting edge
        of the science and technology of the day. This affords us the advantage
        of delivering the best cleaning experience in the industry to southwest
        Virginia. We have grown into one of the most widely respected and
        well-known leaders in Southwest Virginia, with many of our professional
        carpet cleaning competitors referring our services to their clients for
        problems or specialty textiles.
      </p>
      <p>
        Sci-Tech Carpet Cleaning is ideally located to offer superior service to
        Christiansburg, Blacksburg, and Radford, as well as the rest of the New
        River Valley. Our Christiansburg headquarters are in close proximity to
        I-81 which allows us to service Roanoke as well. By being consistently
        dedicated to our thorough cleaning processes and unparalleled{' '}
        <Link to="/guarantee">30-Day No-Hassle Guarantee</Link>, we ensure that
        your carpets and cherished rugs receive the finest cleaning and care
        that the science and technology of the day has to offer. We specialize
        in oriental rug cleaning, tile cleaning, upholstery cleaning, and many
        other services.
      </p>
      <p>
        To learn more about the advantages of Sci-Tech Carpet Cleaning, call us
        today at <a href={config.phoneNumberHref}>{config.phoneNumber}</a> or
        email us through the form below.
      </p>
      <p>Our Office Hours:</p>
      <p>Monday through Friday, 9:00 AM to 5:00 PM</p>
      <address>
        Sci-Tech Carpet Cleaning
        <br />
        740 Radford St.
        <br />
        Christiansburg, VA 24073
        <br />
      </address>
      <p>Same-day service is available in most areas.</p>
      <p>
        <Link to="/certifications-and-associations">
          Click here to see our certifications and associations.
        </Link>
      </p>
      <div style={{ marginBottom: '70px' }} />
      <ContactForm />
    </Wrapper>
  </Layout>
);
